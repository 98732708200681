import dynamic from "next/dynamic";

import { useGetDeviceType } from "_common/hooks/global";
import BackgroundWrapper    from "_components/atoms/backgroundWrapper";
import Render               from "_components/atoms/render";

import useStyles from "./styles";

const Header             = dynamic ( () => import ( "_components/organisms/header" ) );
const Footer             = dynamic ( () => import ( "_components/organisms/footer" ) );
const SideBarMenu        = dynamic ( () => import ( "_components/organisms/sideBarMenu" ) );
const BottomStickyButton = dynamic ( () => import ( "_components/molecules/bottomStickyButton" ) );
const DashboardSidebar   = dynamic ( () => import ( "_components/features/creatorDashboard/molecules/dashboardSidebar" ) );
const AdSlot             = dynamic ( () => import ( "_components/features/ads/slot/single" ) );

const presentation = props => {
	const {
		children,
		checkPageRoute,
		isCreatorDashboard,
		theatreModeToggle,
		headerOnlyLayout
	} = props;

	const isHomePage               = checkPageRoute ( "home" );
	const isStudioPage             = checkPageRoute ( "studio" );
	const isStreamPage             = checkPageRoute ( "stream" );
	const isVideoPage              = checkPageRoute ( "video" );
	const isNotificationsPage      = checkPageRoute ( "notifications" );
	const isModfeedPage            = checkPageRoute ( "modfeed" );
	const isModsPage               = checkPageRoute ( "mods" );
	const isTopCreatorsPage        = checkPageRoute ( "top-creators" );
	const isReelsPage              = checkPageRoute ( "reels" );
	const isGamezopDashboard       = checkPageRoute ( "gaming-dashboard" );
	const isCampaignPage           = checkPageRoute ( "gaming-videos" );
	const isDonatePage             = checkPageRoute ( "donate" );
	const isProPage                = checkPageRoute ( "pro" );
	const isCreatorLeaderBoardPage = checkPageRoute ( "creatorLeaderboard" );
	const deviceType               = useGetDeviceType ();
	const isMobile                 = deviceType === "mobile";

	const { classes } = useStyles ( { theatreModeToggle }, { name: "layout" } );

	const showFooter = !isStreamPage
    && !isStudioPage
    && !isVideoPage
    && !isCreatorDashboard
    && !isMobile
    && !isNotificationsPage
    && !isReelsPage
    && !isGamezopDashboard
    && !isProPage
    && !isCreatorLeaderBoardPage
    && !isDonatePage;

	const hideOpenInAppBtn = isModfeedPage || isModsPage || isTopCreatorsPage || isNotificationsPage || !isMobile || isCampaignPage || isStreamPage;

	if ( headerOnlyLayout ) {
		return (
			<>
				<Header fullWidth = { true } />

				{children}
			</>
		);
	}

	if ( isCreatorDashboard ) {
		return (
			<>
				<Header fullWidth = { true } />

				<div className = { isMobile ? classes.creatorDashMobileLayout : classes.creatorDashLayout }>
					<DashboardSidebar isMobile = { isMobile } />

					{children}
				</div>
			</>
		);
	}

	return (
		<BackgroundWrapper type = "secondary">

			<Render condition = { !theatreModeToggle }>
				<Header fullWidth = { isStreamPage || isStudioPage ? true : false } />

				<div style = { { marginTop: "70px" } }>
					<SideBarMenu />
				</div>
			</Render>

			<div className = { classes.childMainContainer }>
				<div className = { classes.childContainer }>
					{children}
				</div>

			</div>

			<Render condition = { showFooter }>
				<div className = { classes.footerContianer }>
					<Footer />
				</div>
			</Render>

			<Render condition = { isMobile && ( isHomePage || isStreamPage ) }>
				<AdSlot
					id    = { "gpt-passback" }
					index = { "sticky-bottom-home" }
					path  = { "/22991961619/mWeb_Test_1x1" }
					size  = { [ 1, 1 ] }
				/>
			</Render>

			<Render condition = { !hideOpenInAppBtn }>
				<BottomStickyButton title = { "Open in App" } />
			</Render>

		</BackgroundWrapper>
	);
};

export default presentation;
